<template>
  <div class="minWidth1000">
    <custom-actions show-back>
      <div v-if="detailInfo">
        <div class="title-box">基础信息</div>
        <table class="content-table" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>设备名称：</td>
            <td>
              {{ detailInfo.deviceName || "--" }}
            </td>
            <td>所属基站：</td>
            <td>
              {{ detailInfo.stationName || "--" }}
            </td>
          </tr>
          <tr>
            <td>箱外设备串号：</td>
            <td>
              {{ detailInfo.deviceNum || "--" }}
            </td>
            <td>基站内空开串号：</td>
            <td>
              {{ detailInfo.insideDeviceNum || "--" }}
            </td>
          </tr>
        </table>

        <div class="title-box title-box2">基站设备信息</div>
        <table class="content-table" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>基站外设备分合闸：</td>
            <td>
              {{
                detailInfo.deviceNew.deviceState == "1"
                  ? "合闸"
                  : detailInfo.deviceNew.deviceState == "0"
                  ? "分闸"
                  : "--"
              }}
            </td>
            <td>基站内设备分合闸：</td>
            <td>
              {{
                detailInfo.deviceNew.insideDeviceState == "1"
                  ? "合闸"
                  : detailInfo.deviceNew.insideDeviceState == "0"
                  ? "分闸"
                  : "--"
              }}
            </td>
          </tr>
          <tr>
            <td>基站外设备状态：</td>
            <td>
              {{
                detailInfo.deviceNew.deviceDel == "1"
                  ? "在线"
                  : detailInfo.deviceNew.deviceDel == "2"
                  ? "离线"
                  : "--"
              }}
            </td>
            <td>基站内设备状态：</td>
            <td>
              {{
                detailInfo.deviceNew.insideDeviceDel == "1"
                  ? "在线"
                  : detailInfo.deviceNew.insideDeviceDel == "2"
                  ? "离线"
                  : "--"
              }}
            </td>
          </tr>
          <tr>
            <td>基站外设备实时电流：</td>
            <td>
              {{ detailInfo.deviceNew.deviceAmpere || "--" }}
            </td>
            <td>基站内设备实时电流：</td>
            <td>
              {{ detailInfo.deviceNew.insideDeviceAmpere || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外漏电电流：</td>
            <td>
              {{ detailInfo.deviceNew.ldData || "--" }}
            </td>
            <td>基站内漏电电流：</td>
            <td>
              {{ detailInfo.deviceNew.insideLdData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外A相电流：</td>
            <td>
              {{ detailInfo.deviceNew.laData || "--" }}
            </td>
            <td>基站内A相电流：</td>
            <td>
              {{ detailInfo.deviceNew.insideLaData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外B相电流：</td>
            <td>
              {{ detailInfo.deviceNew.lbData || "--" }}
            </td>
            <td>基站内B相电流：</td>
            <td>
              {{ detailInfo.deviceNew.insideLbData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外C相电流：</td>
            <td>
              {{ detailInfo.deviceNew.lcData || "--" }}
            </td>
            <td>基站内C相电流：</td>
            <td>
              {{ detailInfo.deviceNew.insideLcData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外A相电压：</td>
            <td>
              {{ detailInfo.deviceNew.vaData || "--" }}
            </td>
            <td>基站内A相电压：</td>
            <td>
              {{ detailInfo.deviceNew.insideVaData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外B相电压：</td>
            <td>
              {{ detailInfo.deviceNew.vbData || "--" }}
            </td>
            <td>基站内B相电压：</td>
            <td>
              {{ detailInfo.deviceNew.insideVbData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外C相电压：</td>
            <td>
              {{ detailInfo.deviceNew.vcData || "--" }}
            </td>
            <td>基站内C相电压：</td>
            <td>
              {{ detailInfo.deviceNew.insideVcData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外A相温度：</td>
            <td>
              {{ detailInfo.deviceNew.waData || "--" }}
            </td>
            <td>基站内A相温度：</td>
            <td>
              {{ detailInfo.deviceNew.insideWaData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外B相温度：</td>
            <td>
              {{ detailInfo.deviceNew.wbData || "--" }}
            </td>
            <td>基站内B相温度：</td>
            <td>
              {{ detailInfo.deviceNew.insideWbData || "--" }}
            </td>
          </tr>
          <tr>
            <td>基站外C相温度：</td>
            <td>
              {{ detailInfo.deviceNew.wcData || "--" }}
            </td>
            <td>基站内C相温度：</td>
            <td>
              {{ detailInfo.deviceNew.insideWcData || "--" }}
            </td>
          </tr>
        </table>

        <div class="title-box title-box2">保护设置</div>
        <table class="content-table" border="0" cellspacing="0" cellpadding="0">
          <tr>
            <td>瞬时保护：</td>
            <td>
              <div
                :class="
                  detailInfo.instantaneous == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
            <td>短延时保护：</td>
            <td>
              <div
                :class="
                  detailInfo.shortDelay == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
          </tr>

          <tr>
            <td>过载保护：</td>
            <td>
              <div
                :class="
                  detailInfo.overload1 == '0' &&
                  detailInfo.overload2 == '0' &&
                  detailInfo.overload3 == '0'
                    ? 'close-style'
                    : 'open-style'
                "
              ></div>
            </td>
            <td>漏电保护：</td>
            <td>
              <div
                :class="
                  detailInfo.leakageAction == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td>过电压保护：</td>
            <td>
              <div
                :class="
                  detailInfo.overvoltage == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
            <td>欠电压保护：</td>
            <td>
              <div
                :class="
                  detailInfo.underVoltage == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td>超温保护：</td>
            <td>
              <div
                :class="
                  detailInfo.overTemperature == '0'
                    ? 'close-style'
                    : 'open-style'
                "
              ></div>
            </td>
            <td>拉弧保护：</td>
            <td>
              <div
                :class="
                  detailInfo.arc1 == '0' && detailInfo.arc2 == '0'
                    ? 'close-style'
                    : 'open-style'
                "
              ></div>
            </td>
          </tr>
          <tr>
            <td>缺相保护：</td>
            <td>
              <div
                :class="
                  detailInfo.openPhase == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
            <td>防偷电电流差值：</td>
            <td>
              <div
                :class="
                  detailInfo.antiStealing == '0' ? 'close-style' : 'open-style'
                "
              ></div>
            </td>
          </tr>
        </table>
      </div>
    </custom-actions>
  </div>
</template>
<script>
export default {
  name: 'DeviceDetail',
  data () {
    return {
      loading: false,
      detailInfo: null
    }
  },
  created () {
    const id = this.$route.params.id || ''
    console.log('id: ', id)
    // 编辑
    id && this.getDetail(id)
  },

  methods: {
    getDetail (deviceNum) {
      this.loading = true
      this.$axios
        .get(this.$apis.device.selectDeviceByDeviceNum, {
          params: { deviceNum }
        })
        .then(res => {
          console.log('res: ', res)
          res.overload1 = res.overload.split('_')[0] || '0'
          res.overload2 = res.overload.split('_')[1] || '0'
          res.overload3 = res.overload.split('_')[2] || '0'
          res.arc1 = res.arc.split('_')[0] || ''
          res.arc2 = res.arc.split('_')[1] || ''
          this.detailInfo = res
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.row {
  display: flex;
  margin-bottom: 20px;
  font-size: 14px;
  .label {
    width: 150px;
    text-align: right;
  }
  .value {
    word-break: break-all;
    margin-left: 14px;
  }
}
.title-box {
  font-size: 14px;
  margin: 10px 0 30px 0;
  padding-left: 10px;
  position: relative;
  &.title-box2 {
    margin-top: 40px;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 3px;
    height: 14px;
    background: #007165;
  }
}
.content-table {
  width: 860px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: collapse;

  tr {
    td {
      font-size: 14px;
      line-height: 38px;
      display: inline-block;
      width: 25%;
      height: 38px;
      border-right: 1px solid #ccc;
      border-top: 1px solid #ccc;
      text-align: left;
      padding: 0 10px;
      &:nth-child(2n-1) {
        text-align: right;
      }

      .td-value {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
.close-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: #f40909;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(255, 0, 0, 0.8);
}
.open-style {
  width: 36px;
  height: 8px;
  display: inline-block;
  overflow: visible;
  background-color: green;
  border-radius: 16px;
  box-shadow: 0px 0px 6px rgba(0, 255, 0, 0.8);
}
</style>
